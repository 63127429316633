import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Box } from 'rebass';
import Banner from '../components/Banner';
import EventRow from '../components/EventRow';
import EventsCorporateServices from '../components/EventsCorporateServices';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Toggle from '../components/Toggle';

class Event extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: 0,
    };
  }

  setActiveItem = (index) => {
    this.setState({
      activeItem: index,
    });
  };

  render() {
    const {
      data: { events: eventsData },
    } = this.props;

    const { activeItem } = this.state;

    const events = eventsData.nodes;

    return (
      <div>
        <Helmet>
          <title>Event</title>
        </Helmet>
        <Banner />
        <Header
          activePageName="EVENT"
          rightText={activeItem === 0 ? 'OUR SERVICES' : 'ALL EVENTS'}
        />

        <Toggle
          activeItem={activeItem}
          firstItem="CORPORATE EVENT SERVICES"
          secondItem="PUBLIC EVENTS"
          setActiveItem={this.setActiveItem}
        />
        <div id="content">
          {activeItem === 0 && <EventsCorporateServices />}
          {activeItem === 1 && (
            <Box mb={['0', '0', '-15px']}>
              {events.map((event) => (
                <EventRow event={event} key={event.id} />
              ))}
            </Box>
          )}
        </div>
        <Footer activePageName="EVENT" />
      </div>
    );
  }
}

Event.propTypes = {
  data: PropTypes.shape({
    events: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          artists: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
          date: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          place: PropTypes.shape({
            city: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }),
  }).isRequired,
};

export default Event;

export const query = graphql`
  {
    events: allEventsJson(
      filter: { isEvent: { eq: true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        artists
        place {
          name
          city
        }
        date
        eventUrl
        ticketsUrl
      }
    }
  }
`;
