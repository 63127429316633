import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Box, Text } from 'rebass';
import AnimatedContainer from '../AnimatedContainer';
import ContactItem from '../ContactItem';
import TextSection from '../TextSection';
import Events from './Events';
import Logos from './Logos';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pageData: corporateEventServicesPageJson {
          events {
            date
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            name
          }
          logos {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          ourServicesHtml
          contacts {
            name
            title
            email
          }
        }
      }
    `}
    render={({ pageData }) => (
      <Box
        css={{
          maxWidth: '1500px',
        }}
        mx="auto"
        mt={['25px', '30px', '70px']}
        px={[20, 30, 70]}
      >
        <AnimatedContainer>
          <Logos logos={pageData.logos} />
        </AnimatedContainer>
        <AnimatedContainer>
          <TextSection title="Our services">
            <Text
              color="black"
              dangerouslySetInnerHTML={{
                __html: pageData.ourServicesHtml,
              }}
              fontFamily="grot10"
              fontSize={['16px', '16px', '21px']}
              letterSpacing="-0.5px"
              lineHeight={['22px', '22px', '25px']}
            />
          </TextSection>
        </AnimatedContainer>
        {pageData.events.length >= 2 && (
          <AnimatedContainer>
            <Events events={pageData.events} />
          </AnimatedContainer>
        )}
        <AnimatedContainer>
          <TextSection flexContainer isLast title="Contact">
            {pageData.contacts.map((contact, index) => (
              <ContactItem
                contactsLength={pageData.contacts.length}
                email={contact.email}
                index={index}
                key={contact.email}
                name={contact.name}
                title={contact.title}
              />
            ))}
          </TextSection>
        </AnimatedContainer>
      </Box>
    )}
  />
);
