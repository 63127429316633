import Img from 'gatsby-image';
import React from 'react';
import { Box, Flex } from 'rebass';

export default ({ logos }) => (
  <Flex
    css={{
      borderLeft: '1px solid #d9d9d9',
      borderTop: '1px solid #d9d9d9',
    }}
    flexWrap="wrap"
    justifyContent="space-between"
    mt={[35, 70]}
    mb={[25, 30, 70]}
  >
    {logos.map((image, index) => (
      <Box
        css={{
          '&::after': {
            content: "''",
            display: 'block',
            paddingBottom: '100%',
          },
          borderBottom: '1px solid #d9d9d9',
          borderRight: '1px solid #d9d9d9',
          position: 'relative',
        }}
        key={image.id}
        width={['50%', '50%', '25%']}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          css={{
            height: '100%',
            position: 'absolute',
            width: '100%',
          }}
        >
          <Img
            fluid={image.childImageSharp.fluid}
            style={{
              width: '100%',
            }}
          />
        </Flex>
      </Box>
    ))}
  </Flex>
);
