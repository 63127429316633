import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Text } from 'rebass';
import AnimatedContainer from './AnimatedContainer';

const TextSection = ({
  children,
  flexContainer,
  invertColor,
  isLast,
  title,
  ...otherProps
}) => {
  const ContainerComponent = flexContainer ? Flex : Box;

  return (
    <AnimatedContainer>
      <Flex
        css={{
          borderTop: `1px solid ${invertColor ? 'white' : 'black'}`,
          p: {
            margin: 0,
          },
        }}
        flexWrap="wrap"
        pb={isLast ? '25px' : ['30px', '30px', '70px']}
        pt="15px"
        {...otherProps}
      >
        <Text
          color={invertColor ? 'white' : 'black'}
          fontFamily="sans"
          fontSize={['18px', '18px', '19px']}
          mb={['20px', 0]}
          width={['100%', 1 / 2]}
        >
          {title}
        </Text>
        <ContainerComponent flexWrap="wrap" width={['100%', 1 / 2]}>
          {children}
        </ContainerComponent>
      </Flex>
    </AnimatedContainer>
  );
};

TextSection.propTypes = {
  children: PropTypes.node.isRequired,
  flexContainer: PropTypes.bool,
  invertColor: PropTypes.bool,
  isLast: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

TextSection.defaultProps = {
  flexContainer: false,
  invertColor: false,
  isLast: false,
};

export default TextSection;
