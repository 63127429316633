import PropTypes from 'prop-types';
import React from 'react';
import { Box, Link, Text } from 'rebass';

const ContactItem = ({
  contactsLength,
  email,
  index,
  invertColor,
  name,
  title,
}) => (
  <Box
    width={['100%', '100%', 1 / 2]}
    mb={[
      index === contactsLength - 1 ? 0 : '20px',
      index === contactsLength - 1 ? 0 : '15px',
      index < contactsLength - 2 ? '40px' : 0,
    ]}
  >
    <Text
      color={invertColor ? 'white' : 'black'}
      fontFamily="grot10"
      fontSize={['16px', '16px', '21px']}
      letterSpacing="-0.5px"
      lineHeight={['22px', '22px', '25px']}
      mb={[0, 0, '10px']}
    >
      {name}
      <br />
      {title}
    </Text>
    <Link
      color={invertColor ? 'white' : 'black'}
      css={{
        '&:hover': {
          textDecorationColor: 'transparent',
        },
        textDecoration: 'underline',
        textDecorationColor: invertColor ? 'white' : 'black',
        transition: 'all 0.1s ease-in-out',
      }}
      href={`mailto:${email}`}
    >
      <Text
        color={invertColor ? 'white' : 'black'}
        fontFamily="grot10"
        fontSize={['16px', '16px', '21px']}
        letterSpacing="-0.5px"
        lineHeight={['22px', '22px', '25px']}
      >
        {email}
      </Text>
    </Link>
  </Box>
);

ContactItem.propTypes = {
  contactsLength: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  invertColor: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

ContactItem.defaultProps = {
  invertColor: false,
};

export default ContactItem;
