import dayjs from 'dayjs';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import Hide from '../Hide';

const Events = ({ events }) => {
  const [desktopOffset, setDesktopOffset] = useState(0);
  const [mobileIndex, setMobileIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMobileIndex((currentIndex) =>
        currentIndex === events.length - 1 ? 0 : currentIndex + 1,
      );

      setDesktopOffset((currentOffset) =>
        currentOffset === events.length - 2 ? 0 : currentOffset + 2,
      );
    }, 5000);

    return () => clearInterval(interval);
  });

  return (
    <Box mb={[25, 30, 70]}>
      <Hide display={['inline', 'none', 'none']}>
        <Img fluid={events[mobileIndex].image.childImageSharp.fluid} />
        <Flex alignItems="center" mt="15px">
          <Text
            color="black"
            fontFamily="grot10"
            fontSize={['16px', '16px', '21px']}
            letterSpacing="-0.5px"
            lineHeight={['22px', '22px', '25px']}
          >
            {events[mobileIndex].name}
          </Text>
          <Box
            css={{
              border: '1px solid black',
              borderRadius: '2px',
              textTransform: 'uppercase',
            }}
            letterSpacing="0.5px"
            ml="8px"
            mt="2px"
            padding="2px 4px"
            fontFamily="sans"
            fontSize="8px"
          >
            {dayjs(events[mobileIndex].date).format('DD MMM. YYYY')}
          </Box>
        </Flex>
      </Hide>
      <Hide display={['none', 'inline', 'inline']}>
        <Flex flexWrap="wrap" justifyContent="space-between" mb={[25, 30, 70]}>
          {events.slice(desktopOffset, desktopOffset + 2).map((event) => (
            <Box
              key={event.name}
              width={[1, 'calc(50% - 2.5px)', 'calc(50% - 5px)']}
            >
              <Img fluid={event.image.childImageSharp.fluid} />
              <Flex alignItems="center" mt="15px">
                <Text
                  color="black"
                  fontFamily="grot10"
                  fontSize={['16px', '16px', '21px']}
                  letterSpacing="-0.5px"
                  lineHeight={['22px', '22px', '25px']}
                >
                  {event.name}
                </Text>
                <Box
                  css={{
                    border: '1px solid black',
                    borderRadius: '2px',
                    textTransform: 'uppercase',
                  }}
                  letterSpacing="0.5px"
                  ml="8px"
                  mt="2px"
                  padding="2px 4px"
                  fontFamily="sans"
                  fontSize="8px"
                >
                  {dayjs(event.date).format('DD MMM. YYYY')}
                </Box>
              </Flex>
            </Box>
          ))}
        </Flex>
      </Hide>
    </Box>
  );
};

export default Events;
